@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700,900|Noto+Serif+JP:200,300,400,500,600,700,900&amp;subset=japanese);
.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: #1a1a1a;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-style: normal;
  font-weight: bold;
  word-wrap: break-word;
  font-size: 16px;
  min-width: 100%;
  overflow: hidden;
}

.inner {
  max-width: 1200px;
  margin: 0 auto;
}

@media only screen and (max-width: 640px) {
  .inner {
    padding: 20px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .inner {
    padding: 64px 10px 20px;
  }
}

.inner2 {
  max-width: 1490px;
  margin: 0 auto;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
}

#contents {
  width: 100%;
  max-width: 1200px;
  padding-bottom: 200px;
}

@media only screen and (max-width: 834px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

a {
  transition: 1s;
}

.imgR, .imgL {
  float: none	!important;
  margin-left: 0;
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    max-width: 100% !important;
    text-align: center;
  }
}

.imgL {
  float: none;
  max-width: 100% !important;
}

.mincho {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  text-align: center;
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

.hed_cont a {
  border: 1px solid #0092e4;
  color: #0092e4 !important;
  width: 230px;
  margin: 0 auto;
  display: block;
  padding: 15px;
  position: relative;
}

.hed_cont a:hover {
  background: #0092e4;
  color: #ffffff !important;
}

.hed_cont a:before {
  font-family: "Font Awesome 5 pro";
  content: "\f105";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  font-size: 1em;
  /*サイズ*/
  right: 4%;
  top: 30%;
}

#header {
  padding: 50px 10px;
  transition: 0.5s ease-in-out;
}

@media only screen and (max-width: 834px) {
  #header {
    display: block;
    background: none;
    padding: 0;
    margin: 30px 0;
  }
}

#header #siteID {
  text-align: center;
  margin-bottom: 20px;
}

@media only screen and (max-width: 834px) {
  #header #siteID {
    text-align: center;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #header #siteID {
    text-align: center;
    margin-bottom: 10px;
  }
}

#header #siteID a {
  opacity: 1;
  display: block;
}

.hed_under {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .hed_under {
    flex-direction: column-reverse;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 834px) {
  .hed_under {
    display: block;
    padding: 0;
  }
}

.fixed {
  position: fixed !important;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-top: 0 !important;
  padding: 30px 10px !important;
}

.hed_right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .hed_right {
    justify-content: center;
  }
}

.hed_right .line_btn {
  margin-left: 10px;
}

.hed_right .insta_btn {
  margin-left: 10px;
}

#nav_global {
  width: 100%;
}

@media only screen and (max-width: 834px) {
  #nav_global {
    display: none;
  }
}

#nav_global .inner {
  padding: 0;
}

#nav_global ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#nav_global ul .pcnone {
  display: none;
}

#nav_global ul .spnone {
  display: block;
}

@media only screen and (max-width: 640px) {
  #nav_global ul .spnone {
    display: none;
  }
}

#nav_global ul li {
  z-index: 1;
  position: relative;
}

#nav_global ul li a {
  width: 100%;
  color: #1a1a1a !important;
  padding: 10px;
  display: block;
  font-size: 14px;
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  #nav_global ul li a {
    font-size: 14px;
  }
}

#nav_global ul li a:hover {
  opacity: 1;
  -webkit-animation: .3s;
  animation: zoom .3s;
}

#nav_global ul ul {
  position: absolute;
  width: 200px;
  z-index: 100;
  display: block;
  background: rgba(255, 255, 255, 0.7);
  text-align: center;
  left: -65%;
  right: 0;
}

#nav_global ul ul li {
  border-bottom: 1px solid #586166;
  font-size: 14px;
  border-bottom: none;
}

#nav_global ul ul li a {
  color: #1a1a1a;
  padding: 15px 0;
}

#mainArea2 {
  width: 100%;
}

#mainArea2 #local-keyvisual-title {
  background: url(../images/contents/local_main06.jpg) no-repeat;
  background-size: cover;
  width: 100% !important;
}

.fix_btn {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 1;
}

.fix_btn a {
  display: block;
  position: relative;
}

@media only screen and (max-width: 834px) {
  .fix_btn a {
    width: 100%;
  }
}

.mainwrap {
  overflow: hidden;
}

.swiper-container {
  width: 100%;
  max-width: 1350px !important;
  margin: 0 auto !important;
  overflow: visible !important;
}

/* 前ページ、次ページボタン共通のスタイル */
.swiper-button-prev,
.swiper-button-next {
  background-image: none;
  /* デフォルトのボタン画像を消す */
  width: 28px;
  /* ボタンの幅 */
  height: 86px;
  /* ボタンの高さ */
  margin-top: -43px;
  /* 縦中央配置用：ボタンの高さの半分のネガティブマージン（top:50%がすでに設定されている） */
}

/* 前ページボタンのスタイル */
.swiper-button-prev:after {
  content: url(../images/home/arrow_L.png);
}

/* 次ページボタンのスタイル */
.swiper-button-next:after {
  content: url(../images/home/arrow_R.png);
}

.swiper-pagination-bullet-active {
  background: #586166 !important;
}

#local-keyvisual {
  position: relative;
  z-index: 0;
}

#local-keyvisual:empty {
  display: none;
}

#local-keyvisual h1, #local-keyvisual .blog_main {
  color: #0092e4;
  font-size: 3em;
  z-index: 1;
  position: relative;
  text-align: center;
  line-height: 1.2em;
  width: 100%;
  padding: 5% 10px;
  margin: 30px 0;
  background-color: rgba(255, 255, 255, 0.7);
  max-width: 50vw;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-shadow: 0px 0px 10px #fff;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

@media only screen and (max-width: 834px) {
  #local-keyvisual h1, #local-keyvisual .blog_main {
    font-size: 1.5em;
  }
}

#local-keyvisual h1 br, #local-keyvisual .blog_main br {
  display: none;
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 br, #local-keyvisual .blog_main br {
    display: block;
  }
}

#local-keyvisual h1:empty, #local-keyvisual .blog_main:empty {
  display: none;
}

#local-keyvisual h1 span, #local-keyvisual .blog_main span {
  display: block;
  font-size: 24px;
  font-family: 'Cormorant Garamond', serif;
  font-weight: bold;
  margin-top: 10px;
}

.pankuzu {
  display: flex;
  justify-content: center;
  align-items: center;
}

#slider {
  position: relative;
  z-index: 1;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-size: cover;
  background-position: center 30%;
}

#local-keyvisual #local-keyvisual-bg:empty, #local-keyvisual .local-keyvisual-bg:empty {
  display: none;
}

#local-keyvisual-title:empty {
  display: none;
}

.pan1 {
  margin: 20px 0 100px;
}

@media only screen and (max-width: 834px) {
  .pan1 {
    text-align: center;
  }
}

.pan1 a {
  color: #000;
}

.toggleMenu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 49px;
  width: 49px;
  background-size: contain;
  position: fixed;
  top: 11px;
  right: 5px;
  z-index: 100;
  transition: 0.5s ease-in-out;
  background: #586166;
}

.toggleMenu:after {
  content: 'MENU';
  font-size: 13px;
  color: #586166;
  display: block;
  position: absolute;
  bottom: -50%;
  left: 8%;
  font-weight: bold;
  font-family: 'Cormorant Garamond', serif;
}

@media only screen and (max-width: 640px) {
  .toggleMenu {
    width: 49px;
  }
}

.hide {
  transform: translateY(-200%);
}

.toggleMenu .bars {
  margin: 0 30px 0 15px;
  height: 22px;
  width: 35px;
  position: relative;
}

.toggleMenu a:hover {
  opacity: 1;
}

.toggleMenu .bars span {
  height: 3px;
  background-color: #fff;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  position: absolute;
  left: -5px;
}

.toggleMenu .bars span:nth-of-type(1) {
  width: 30px;
  top: 0;
}

.toggleMenu .bars span:nth-of-type(2) {
  width: 30px;
  top: 10px;
}

.toggleMenu .bars span:nth-of-type(3) {
  width: 30px;
  top: 20px;
}

.toggleMenu.isActive .bars span {
  right: 0;
}

.toggleMenu.isActive .bars span:nth-of-type(1), .toggleMenu.isActive .bars span:nth-of-type(3) {
  width: 30px;
  top: 10px;
}

.toggleMenu.isActive .bars span:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.toggleMenu.isActive .bars span:nth-of-type(2) {
  opacity: 0;
}

.toggleMenu.isActive .bars span:nth-of-type(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.overlayMenu {
  width: 100%;
  -webkit-transition: -webkit-transform .8s ease;
  transition: -webkit-transform .8s ease;
  transition: transform .8s ease;
  transition: transform .8s ease, -webkit-transform .8s ease;
  z-index: 10;
  position: fixed;
  top: 0;
  right: 100%;
  bottom: 0;
  background: #0092e4;
  opacity: 0;
  transition: opacity .6s ease, visibility .6s ease;
}

.fixed .overlayMenu {
  visibility: visible;
  opacity: 1;
}

.overlayMenu.isOpened {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.overlayMenu > .outer {
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background: url(../images/about/concept_bg.jpg) center;
  background-size: cover;
  padding-top: 150px !important;
}

.overlayMenu > .outer .inner {
  padding: 0 100px;
}

.overlayMenu > .outer .sp_hed_right {
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlayMenu > .outer .sp_hed_right .line_btn {
  margin-right: 10px;
}

@media only screen and (max-width: 834px) {
  .overlayMenu > .outer .sp_hed_right .hed_cont a {
    border: 1px solid #0092e4;
    color: #0092e4;
    width: 230px;
    margin: 0 auto;
    display: block;
    padding: 15px;
    position: relative;
  }
  .overlayMenu > .outer .sp_hed_right .hed_cont a:before {
    font-family: "Font Awesome 5 pro";
    content: "\f105";
    /*アイコンのユニコード*/
    position: absolute;
    /*絶対位置*/
    font-size: 1em;
    /*サイズ*/
    right: 4%;
    top: 30%;
  }
}

@media only screen and (max-width: 834px) {
  .overlayMenu > .outer {
    padding: 20px 0;
  }
}

.overlayMenu > .outer li a {
  color: #1a1a1a;
  padding: 20px 0;
  display: block;
  font-size: 24px;
  border-bottom: 1px solid #f7f7f7;
  width: 250px;
  margin: 0 auto;
}

.overlayMenu:before, .overlayMenu:after {
  content: "";
  height: 20px;
  background-size: cover;
  position: absolute;
  left: 10px;
  right: 10px;
}

.overlayMenu:before {
  top: 0;
}

.overlayMenu:after {
  bottom: 0;
}

@media (max-width: 835px) {
  .pcOnly {
    display: none !important;
  }
}

@media (min-width: 835px) {
  .smOnly {
    display: none !important;
  }
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  text-align: center;
  background: #efefef;
  position: relative;
}

footer#global_footer .inner {
  padding: 50px 10px 80px;
}

@media only screen and (max-width: 834px) {
  footer#global_footer .inner {
    padding: 30px 0;
  }
}

footer#global_footer a {
  text-decoration: none;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  footer#global_footer #nav_footer {
    justify-content: center;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 640px) {
  footer#global_footer #nav_footer {
    justify-content: center;
    margin-top: 15px;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  footer#global_footer #nav_footer {
    justify-content: center;
    margin-top: 15px;
  }
}

footer#global_footer #nav_footer ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  footer#global_footer #nav_footer ul {
    display: block;
  }
}

footer#global_footer #nav_footer li a {
  color: #1a1a1a !important;
  text-decoration: none;
  padding: 10px;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  display: block;
}

@media only screen and (max-width: 834px) {
  footer#global_footer #nav_footer li a {
    background: #586166;
    color: #ffffff !important;
    margin: 5px;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  footer#global_footer #nav_footer li a {
    font-size: 14px;
  }
}

footer#global_footer #nav_footer li a:hover {
  opacity: 0.6;
}

footer#global_footer #copy a {
  text-decoration: none;
  color: #333333 !important;
}

footer#global_footer #copy a:hover {
  opacity: 0.6;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  footer#global_footer #copy a {
    line-height: 60px;
  }
}

#copy {
  color: #333333;
}

.pagetop {
  position: fixed;
  left: 10px;
  bottom: 10px;
  z-index: 2;
  opacity: 1;
}

.pagetop a {
  display: block;
  color: #ffffff;
  background: #0092e4;
  height: 60px;
  width: 60px;
  display: block;
  border-radius: 5px;
  font-size: 1.5em;
  padding: 15px;
}

#footer1 .ftcontwrap {
  position: absolute;
  right: 0;
  left: 0;
  top: -20%;
}

#footer1 .ftcontwrap a {
  font-family: 'Cormorant Garamond', serif;
  padding: 80px 0;
  color: #ffffff !important;
  font-size: 2.1em;
  position: relative;
  width: 800px;
  display: block;
  margin: 0 auto;
  background: #586166;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 834px) {
  #footer1 .ftcontwrap a {
    width: 100%;
    margin-top: 15px;
  }
}

#footer1 .ftcontwrap a:before {
  font-family: "Font Awesome 5 pro";
  content: "\f105";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  font-size: 1em;
  /*サイズ*/
  right: 4%;
  top: 42%;
}

#footer2 {
  padding: 160px 10px 60px;
}

#footer2 .blue {
  color: #0092e4;
}

#footer2 .td_time {
  max-width: 900px;
  margin: 0 auto;
  background: #fff;
}

#footer2 .td_time table {
  width: 100%;
}

#footer2 .td_time td {
  padding: 15px;
  border: 1px solid #ccc;
  vertical-align: middle;
}

#footer2 .td_txt {
  max-width: 900px;
  text-align: left;
  margin: 0 auto;
}

#footer2 .td_txt span {
  color: #0092e4;
}

#footer2 .td_time br {
  display: none;
}

@media only screen and (max-width: 834px) {
  #footer2 .td_time br {
    display: block;
  }
}

#footer2 .ftwrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

@media only screen and (max-width: 834px) {
  #footer2 .ftwrap {
    display: block;
  }
}

@media screen and (min-width: 834px) and (max-width: 1300px) {
  #footer2 .ftwrap {
    display: block;
  }
}

.ft_L {
  margin-right: 15px;
}

@media only screen and (max-width: 834px) {
  .ft_L {
    margin-right: 0;
  }
}

@media screen and (min-width: 834px) and (max-width: 1300px) {
  .ft_L {
    margin-bottom: 15px;
  }
}

.h2_01 h2 {
  font-size: 28px;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  line-height: 1.5em;
}

.h2_01 h2 br:nth-of-type(1) {
  display: none;
}

@media only screen and (max-width: 834px) {
  .h2_01 h2 br:nth-of-type(1) {
    display: block;
  }
}

.h2_01 h2 span {
  font-size: 26px;
  color: #0092e4;
  display: block;
  padding-bottom: 60px;
  position: relative;
  font-family: 'Cormorant Garamond', serif;
}

.h2_01 h2 span:after {
  content: '';
  height: 35px;
  width: 1px;
  display: block;
  background: #d2ebf6;
  position: absolute;
  right: 0;
  left: 0;
  top: 40%;
  margin: 0 auto;
}

.h2_02 h2 {
  font-size: 28px;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  line-height: 1.5em;
  position: relative;
}

.h2_02 h2:after {
  content: '';
  width: 35px;
  height: 1px;
  background: #0092e4;
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  bottom: -20%;
  margin: 0 auto;
}

.h2_02 h2 span {
  font-family: 'Cormorant Garamond', serif;
  font-size: 26px;
  color: #0092e4;
  display: block;
  padding-bottom: 10px;
  position: relative;
}

.h2_03 h2 {
  font-size: 28px;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  line-height: 1.5em;
  position: relative;
}

.h2_03 h2:after {
  content: '';
  width: 35px;
  height: 1px;
  background: #0092e4;
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  bottom: -20%;
}

.h2_03 h2 span {
  font-family: 'Cormorant Garamond', serif;
  font-size: 26px;
  color: #0092e4;
  display: block;
  padding-bottom: 10px;
  position: relative;
}

.top_txt div br:nth-of-type(2) {
  display: none;
}

@media only screen and (max-width: 834px) {
  .top_txt div br:nth-of-type(2) {
    display: block;
  }
}

.txt {
  line-height: 2em;
}

@media only screen and (max-width: 640px) {
  .toptxt div {
    text-align: left !important;
  }
}

.sec_01wrap {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  background: url(../images/home/cont1_bg.jpg);
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  padding: 190px 10px;
  margin-bottom: 100px;
}

@media only screen and (max-width: 640px) {
  .sec_01wrap {
    justify-content: center;
  }
}

.bnr .imgC {
  width: 100% !important;
  height: auto !important;
}

.sec_01 {
  margin-right: 50px;
}

@media only screen and (max-width: 834px) {
  .sec_01 {
    margin-right: 0;
  }
}

.sec_01 .cont02 {
  display: flex;
  justify-content: center;
  background-color: rgba(239, 239, 239, 0.8);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 30px;
}

@media only screen and (max-width: 640px) {
  .sec_01 .cont02 {
    display: block;
  }
}

.sec_01 .cont02 .list_L li, .sec_01 .cont02 .list_R li {
  position: relative;
  padding: 10px 0 10px 50px;
}

.sec_01 .cont02 .list_L li:before, .sec_01 .cont02 .list_R li:before {
  content: url(../images/home/check_icon.png);
  position: absolute;
  left: 0;
  top: 0;
}

.sec_01 .cont02 .list_R {
  margin-left: 15px;
}

@media only screen and (max-width: 640px) {
  .sec_01 .cont02 .list_R {
    margin-left: 0;
  }
}

.sec_01 .online_support {
  display: block !important;
  background: #fff;
  padding: 35px 80px;
  display: block;
  width: 480px;
  margin: 0 auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.sec_01 .online_support .box:nth-of-type(1) {
  font-size: 1.25em;
  text-align: center;
  border-bottom: 1px dotted #0092e4;
  padding-bottom: 5px;
  margin-bottom: 15px;
}

.sec_01 .online_support .box:nth-of-type(3) div {
  font-size: 1.25em;
  font-weight: bold;
  text-align: center;
  margin-top: 15px;
}

.sec_02wrap {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  background: url(../images/home/cont3_img_pc.jpg) left bottom;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  padding: 190px 10px;
  margin-bottom: 100px;
}

@media only screen and (max-width: 834px) {
  .sec_02wrap {
    justify-content: center;
    background: url(../images/home/cont3_img_sp.jpg) no-repeat top;
    padding: 680px 10px 100px;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .sec_02wrap {
    justify-content: center;
    background: url(../images/home/cont3_img_sp.jpg) no-repeat top;
    padding: 680px 10px 100px;
  }
}

.sec_02 {
  margin-right: 10%;
}

@media only screen and (max-width: 834px) {
  .sec_02 {
    background-color: rgba(239, 239, 239, 0.8);
    padding: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin-right: 0;
  }
}

@media screen and (min-width: 834px) and (max-width: 1280px) {
  .sec_02 {
    background-color: rgba(239, 239, 239, 0.8);
    padding: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
}

.bnrbox {
  justify-content: space-between;
}

.bnrbox .box {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .bnrbox .box:nth-of-type(2) {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 640px) {
  .bnrbox .box {
    width: 100%;
  }
}

.btn a {
  border: 1px solid #0092e4;
  color: #0092e4;
  width: 330px;
  margin: 0 auto;
  display: block;
  padding: 15px;
  position: relative;
  background: #fff;
}

.btn a:hover {
  background: #0092e4;
  color: #ffffff;
}

.btn a:before {
  font-family: "Font Awesome 5 pro";
  content: "\f105";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  font-size: 1em;
  /*サイズ*/
  right: 4%;
  top: 30%;
}

.btn2 a {
  border: 1px solid #0092e4;
  background: #fff;
  color: #0092e4;
  width: 330px;
  display: block;
  padding: 15px;
  position: relative;
}

.btn2 a:hover {
  background: #0092e4;
  color: #ffffff;
}

.btn2 a:before {
  font-family: "Font Awesome 5 pro";
  content: "\f105";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  font-size: 1em;
  /*サイズ*/
  right: 4%;
  top: 30%;
}

.btn3 a {
  border: 1px solid #0092e4;
  background: #fff;
  color: #0092e4;
  display: block;
  padding: 15px;
  position: relative;
}

.btn3 a:before {
  font-family: "Font Awesome 5 pro";
  content: "\f105";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  font-size: 1em;
  /*サイズ*/
  right: 4%;
  top: 30%;
}

.btn3 a:hover {
  background: #0092e4;
  color: #ffffff;
}

.sec_03 {
  background: url(../images/home/news_bg.jpg) no-repeat;
  background-size: cover;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  padding: 80px 10px;
}

.sec_05 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .sec_05 {
    display: block;
  }
  .sec_05 .flxL {
    width: 100% !important;
    text-align: center;
  }
  .sec_05 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.sec_05 .flxL {
  width: 38%;
}

.sec_05 .flxR {
  width: 58%;
}

.sec_05 .flxR .tenken_wrap div {
  border: 1px solid #0092e4;
  padding: 30px;
  font-weight: bold;
}

.sec_05 .flxR .tenken_wrap div .midashi {
  font-size: 1.5em;
  font-weight: 900;
}

@media only screen and (max-width: 640px) {
  .sec_05 .flxR .tenken_wrap div .midashi {
    font-size: 1.2em;
  }
}

.sec_06wrap {
  background: #586166;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  padding: 80px 10px;
}

.list_tenken li {
  background: #1b1b1b;
  padding: 10px;
  font-weight: bold;
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .list_tenken li {
    width: 100%;
    text-align: center;
  }
  .list_tenken li:first-of-type {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .list_tenken li {
    float: none !important;
    width: 100%;
  }
  .list_tenken li:first-of-type {
    margin-bottom: 10px;
  }
}

.list_tenken li:first-of-type {
  margin-right: 15px;
}

.img-animation {
  overflow: hidden;
  position: relative;
}

.img-animation.animated:after {
  -webkit-animation: img-animation 1.5s cubic-bezier(0.77, 0, 0.175, 1) both;
  animation: img-animation 1.5s cubic-bezier(0.77, 0, 0.175, 1) both;
  background: #fff;
  bottom: 0;
  content: '';
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

@-webkit-keyframes img-animation {
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@keyframes img-animation {
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.newswrap {
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 35px;
  max-width: 1200px;
  margin: 0 auto;
}

.newswrap .news {
  height: 330px;
  overflow-y: auto;
}

.newswrap .news li {
  width: 100%;
}

@media only screen and (max-width: 834px) {
  .newswrap .news {
    padding-left: 0;
    font-size: 16px;
  }
}

.newswrap .news article {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
  border-bottom: 1px dotted #ccc;
}

.newswrap .news article div {
  margin-left: 30px;
}

.newswrap .news .date {
  display: block;
  margin-bottom: 15px;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #fff;
  border: none;
  border-radius: 10px;
  box-shadow: inset 0 0 2px #777;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
  box-shadow: none;
}

.instagram .sns_list li {
  margin-right: 1em;
}

.instagram .sns_text {
  display: none;
}

.instagram div.sns_list {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  border: none !important;
  justify-content: space-between;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .instagram div.sns_list {
    justify-content: flex-start;
  }
}

.instagram div.sns_list > div {
  width: 14% !important;
}

@media only screen and (max-width: 640px) {
  .instagram div.sns_list > div {
    width: 48% !important;
    margin: 5px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .instagram div.sns_list > div {
    width: 33% !important;
  }
}

.instagram div.sns_list div.sns_photo {
  width: 100% !important;
  padding: 0 !important;
}

.instagram div.sns_list > div {
  border-bottom: none !important;
}

.instagram .sns_photo img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .instagram .sns_photo img {
    height: 100% !important;
    width: 100% !important;
  }
}

.h2_04 h2 {
  font-size: 28px;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  line-height: 1.5em;
  background: #f7f7f7;
  padding: 10px;
}

@media only screen and (max-width: 640px) {
  .h2_04 h2 {
    font-size: 24px;
  }
}

.h2_04 div {
  color: #0092e4;
}

.h3_01 h3 {
  font-size: 1.25em;
  font-weight: bold;
  color: #586166;
}

.h3_02 h3 {
  font-weight: bold;
  color: #ffffff;
  background: #586166;
  padding: 5px;
}

.blog dl dt {
  font-size: 20px !important;
  color: #0092e4 !important;
}

.blog dl .clearfix a {
  color: #696969 !important;
}

.blog .date {
  font-size: 16px !important;
}

.blog .saishin:nth-of-type(2) {
  display: none;
}

@media only screen and (max-width: 640px) {
  aside h3:nth-of-type(2), aside nav > div:nth-of-type(2) {
    display: none;
  }
}

.flowbox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flowbox .td_flow {
  width: 31%;
}

@media only screen and (max-width: 640px) {
  .flowbox .td_flow {
    width: 100%;
  }
}

@media only screen and (max-width: 834px) {
  .flowbox .td_flow:nth-of-type(1) .td_txt {
    padding: 10px !important;
  }
}

@media only screen and (max-width: 834px) {
  .flowbox .td_flow:nth-of-type(3) .td_txt {
    padding: 10px !important;
  }
}

.flowbox .td_flow td {
  border: none !important;
}

.flowbox .td_flow .td_title div {
  color: #586166;
  font-weight: bold;
}

.flowbox .td_flow .td_txt div {
  font-size: 14px;
}

.btnlist ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.btnlist li {
  float: none !important;
  width: 49%;
}

@media only screen and (max-width: 640px) {
  .btnlist li {
    width: 100%;
    margin-top: 10px;
  }
}

.btnlist li a {
  background: #0092e4;
  color: #ffffff;
  font-size: 1.25em;
  padding: 15px;
  display: block;
  position: relative;
}

.btnlist li a:before {
  font-family: "Font Awesome 5 pro";
  content: "\f105";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  font-size: 1em;
  /*サイズ*/
  right: 4%;
  top: 30%;
}

.concept_waku {
  display: block !important;
  text-align: center;
}

.concept_waku .box:nth-of-type(1) {
  font-size: 1.25em;
  margin-bottom: 15px;
  color: #0092e4;
}

.concept_waku .box:nth-of-type(2) {
  line-height: 2em;
}

.concept_waku .box br:nth-of-type(1) {
  display: none;
}

@media only screen and (max-width: 834px) {
  .concept_waku .box br:nth-of-type(1) {
    display: block;
  }
}

.concept_waku .box br:nth-of-type(3) {
  display: none;
}

@media only screen and (max-width: 834px) {
  .concept_waku .box br:nth-of-type(3) {
    display: block;
  }
}

.list03 {
  max-width: 250px;
  margin: 0 auto;
}

.list03 li {
  position: relative;
  padding: 10px 0 10px 70px;
}

.list03 li:before {
  content: url(../images/home/check_icon.png);
  position: absolute;
  left: 0;
  top: 0;
}

.tel_cont {
  justify-content: flex-start;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .tel_cont {
    justify-content: center;
  }
}

.tel_cont .box {
  width: 38%;
}

@media only screen and (max-width: 640px) {
  .tel_cont .box {
    width: 100%;
  }
}

.tel_cont .box:nth-of-type(1) .time {
  font-size: 13px;
}

.tel_cont .box:nth-of-type(1) .tel2 {
  font-family: "Times New Roman";
  font-size: 1.8em;
}

.tel_cont .box:nth-of-type(1) .tel2 span {
  font-size: 18px;
}

.tel_cont .box:nth-of-type(1) .tel2 a {
  color: #1a1a1a;
}

.tel_cont .box:nth-of-type(2) {
  text-align: center;
}

.tel_cont .box:nth-of-type(2) a {
  color: #ffffff;
  background: #0092e4;
  padding: 10px;
  display: block;
}

.triangle {
  margin: 0 auto;
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-top: 30px solid #0092e4;
}

.cont_01 {
  border: 4px double #ddf0fb;
  padding: 50px;
}

@media only screen and (max-width: 834px) {
  .cont_01 .txt div {
    text-align: left !important;
  }
}

.cont_02 {
  padding: 50px;
  border: 4px double #ddf0fb;
}

.cont_03 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f7f7f7;
  padding: 50px;
}

@media only screen and (max-width: 640px) {
  .cont_03 {
    display: block;
  }
}

.cont_03 .center {
  width: auto !important;
}

@media only screen and (max-width: 640px) {
  .cont_03 .center {
    margin: 30px auto;
  }
}

.list04 li {
  padding: 15px 0 15px 50px;
  position: relative;
}

.list04 li:before {
  content: url(../images/home/check_icon.png);
  position: absolute;
  left: 0;
  top: 0;
}

.list05 li {
  padding: 15px 0 15px 50px;
  position: relative;
}

.list05 li:before {
  content: url(../images/home/check_icon_pink.png);
  position: absolute;
  left: 0;
  top: 0;
}

.list06 li {
  font-size: 1.3em;
  line-height: 2em;
  color: #586166;
}

.list07 li {
  padding: 5px 0;
  display: block;
}

.list07 li:before {
  content: '・';
  color: #0092e4;
}

.list08 li {
  background: #ddf0fb;
  margin: 5px;
  padding: 5px;
}

.list09 li {
  background: #F8E6E2;
  margin: 5px;
  padding: 5px;
}

.list09 li:before {
  content: url(../images/trial/trial_check2.png);
}

.list10 {
  max-width: 90px;
  margin: 0 auto;
}

.list10 li {
  padding: 5px 0;
}

.list10 li:before {
  content: url(../images/trial/trial_check.png);
}

.list11 li {
  display: block;
}

.list11 li:before {
  content: '・';
  color: #0092e4;
}

.floxbox {
  justify-content: space-between;
}

.floxbox .box {
  width: 31%;
}

@media only screen and (max-width: 640px) {
  .floxbox .box {
    width: 100%;
  }
}

.floxbox .box h3 {
  color: #586166;
  font-weight: bold;
  margin: 10px 0;
}

.floxbox .box article div {
  font-size: 14px;
}

@media only screen and (max-width: 640px) {
  .floxbox .box article div {
    margin-bottom: 15px;
  }
}

.cont_04 {
  border: 4px double #F8E6E2;
  padding: 50px;
}

@media only screen and (max-width: 640px) {
  .cont_04 .txt div {
    text-align: left !important;
  }
}

.cont_04 .list05 {
  max-width: 380px;
  margin: 0 auto;
}

@media only screen and (max-width: 640px) {
  .cont_04 .list05 {
    max-width: 100%;
  }
}

.cont_05, .cont_05_2 {
  border: 10px solid #f7f7f7;
  padding: 15px;
}

@media only screen and (max-width: 834px) {
  .cont_05 .h2_04 h2, .cont_05_2 .h2_04 h2 {
    text-align: center !important;
  }
}

.cont_05 .contwrap, .cont_05_2 .contwrap {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .cont_05 .contwrap, .cont_05_2 .contwrap {
    display: block;
  }
  .cont_05 .contwrap .cont02, .cont_05_2 .contwrap .cont02 {
    width: 100% !important;
    text-align: center;
  }
  .cont_05 .contwrap .cont03, .cont_05_2 .contwrap .cont03 {
    width: 100% !important;
    margin-top: 15px;
  }
}

.cont_05 .cont02, .cont_05_2 .cont02 {
  width: 33%;
}

.cont_05 .cont02 img, .cont_05_2 .cont02 img {
  width: auto !important;
  height: 350px !important;
  object-fit: cover;
  font-family: "object-fit:cover;";
}

@media only screen and (max-width: 834px) {
  .cont_05 .cont02 img, .cont_05_2 .cont02 img {
    width: 100% !important;
    height: auto !important;
  }
}

.cont_05 .cont03, .cont_05_2 .cont03 {
  width: 65%;
}

.cont_05_2 {
  border: none;
}

@media only screen and (max-width: 834px) {
  .cont_05_2 .contwrap {
    display: flex !important;
    flex-direction: column-reverse;
  }
}

.wakuwrap {
  border: 10px solid #f7f7f7;
  padding: 15px;
}

.cont_06 {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  .cont_06 {
    display: block;
  }
  .cont_06 .flxL {
    width: 100% !important;
    text-align: center;
  }
  .cont_06 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.cont_06 .flxL {
  width: 31%;
}

.cont_06 .flxR {
  width: 65%;
}

.cont_07wrap {
  background: url(../images/about/concept_bg.jpg);
  background-size: cover;
  padding: 50px;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

.txt_waku4 {
  background: #DDF0FB;
  padding: 15px;
}

.txt_waku5 {
  background: url(../images/about/concept_bg.jpg);
  background-size: cover;
  padding: 40px;
  line-height: 3em;
}

.txt_waku5 .strong1 {
  font-size: 1.3em;
}

.txt_waku5 .strong2 {
  font-size: 1.3em;
}

.imgbox {
  justify-content: space-between;
}

.imgbox .box {
  width: 31%;
}

@media only screen and (max-width: 640px) {
  .imgbox .box {
    width: 100%;
    margin: 10px;
  }
}

.txt_waku div {
  line-height: 2em;
}

.txt_waku2 div {
  line-height: 2.5em;
  font-size: 2em;
  font-weight: bold;
  padding: 5px;
  margin: 0 auto;
}

.txt {
  line-height: 2em;
}

.mail {
  display: none;
}

.tel2 {
  font-size: 1.7em;
  color: #1a1a1a;
  font-weight: bold;
  font-family: "Times New Roman";
}

.tel2 span {
  font-size: 18px;
  margin-right: 10px;
}

.tel2 a {
  color: #1a1a1a;
}

.cont_tel {
  font-family: archivo-black, sans-serif;
}

.cont_tel .time {
  font-size: 13px;
  font-weight: 400;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.cont_tel a {
  color: #1a1a1a;
  font-weight: bold;
}

.cont_form {
  padding: 30px;
  background: #f7f7f7;
}

@media only screen and (max-width: 640px) {
  .cont_form {
    padding: 30px;
  }
}

.cont_form dl {
  border-bottom: 1px solid #fff;
  margin: 10px;
}

.dl_01 dt {
  border: none !important;
  border-bottom: 1px solid #0092e4 !important;
  flex-direction: inherit !important;
  padding: 20px !important;
}

@media only screen and (max-width: 640px) {
  .dl_01 dt {
    display: block !important;
    width: 100% !important;
  }
}

.dl_01 dd {
  border: none !important;
  border-bottom: 1px solid #ccc !important;
  padding: 20px !important;
}

@media only screen and (max-width: 640px) {
  .dl_01 dd {
    width: 100% !important;
  }
}

@media only screen and (max-width: 640px) {
  .dl_01 dl {
    display: block !important;
  }
}

.dl_02 {
  font-size: 14px;
}

.dl_02 dt {
  border: none !important;
  border-bottom: 1px solid #0092e4 !important;
  flex-direction: inherit !important;
}

.dl_02 dd {
  border: none !important;
  border-bottom: 1px solid #ccc !important;
}

.qa dl {
  display: block !important;
  margin: 15px 0;
  background: #f7f7f7;
  padding: 15px;
}

.qa dt {
  display: block !important;
  width: 100% !important;
  border: none !important;
  position: relative;
  padding: 15px 0 15px 45px !important;
  position: relative;
}

.qa dt:hover {
  cursor: pointer;
}

.qa dt:after {
  font-family: "Font Awesome 5 pro";
  content: "\f13a";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  font-size: 1.5em;
  /*サイズ*/
  right: 0;
  /*アイコンの位置*/
  top: 30%;
  font-weight: bold;
  color: #586166;
}

@media only screen and (max-width: 834px) {
  .qa dt {
    padding-right: 45px !important;
  }
}

.qa dd {
  width: 100% !important;
  border: none !important;
  padding: 15px 0 15px 45px !important;
  position: relative;
}

.qa dd span {
  font-size: 1.25em;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-weight: bold;
}

.qa dt:before {
  content: "Q";
  display: inline-block;
  line-height: 40px;
  padding: 0em;
  color: white;
  background: #0092e4;
  font-weight: bold;
  width: 40px;
  text-align: center;
  height: 40px;
  position: absolute;
  left: 0;
  top: 10px;
}

.qa dd:before {
  content: "A";
  display: inline-block;
  line-height: 40px;
  padding: 0em;
  color: white;
  background: #586166;
  font-weight: bold;
  width: 40px;
  text-align: center;
  height: 40px;
  margin-right: 7px;
  position: absolute;
  left: 0;
  top: 10px;
}

.con100 {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

.gmap {
  z-index: 0;
}

.gmap iframe {
  width: 100%;
}

.slider2 img {
  margin: 0 auto;
}

.bg_100per_wrap {
  position: relative;
  padding: 500px 0 !important;
}

@media only screen and (max-width: 640px) {
  .bg_100per_wrap {
    padding: 550px 0 !important;
  }
}

.bg_100per_inner {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/common/cont2_bg.jpg);
  background-size: cover;
  z-index: 0;
  display: flex;
  justify-content: flex-end;
  background-attachment: fixed;
}

@media only screen and (max-width: 834px) {
  .bg_100per_inner {
    display: block;
  }
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 1px solid #0092e4;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 640px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.policy {
  padding: 10px 0;
  text-align: center;
}

@media only screen and (max-width: 834px) {
  .policy {
    text-align: left;
  }
}

.policy a {
  color: #0092e4;
}

#TRANS_ANNOT {
  display: none;
}

.slide-bottom {
  opacity: 0;
  transform: translate(0, 50px);
  transition: all 1500ms;
}

.slide-bottom.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-top {
  opacity: 0;
  transform: translate(0, -50px);
  transition: all 1500ms;
}

.slide-top.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-right {
  opacity: 0;
  transform: translate(50px, 0px);
  transition: all 1500ms;
}

.slide-right.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-left {
  opacity: 0;
  transform: translate(-50px, 0px);
  transition: all 1500ms;
}

.slide-left.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

@keyframes fadeIn_left {
  0% {
    opacity: 0;
    transform: translate(-50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn_right {
  0% {
    opacity: 0;
    transform: translate(50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(0, 50px);
  }
  100% {
    opacity: 1;
  }
}

.sns {
  margin-top: 50px;
}

#media {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
}

#media .row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

#media .row .box {
  width: 31%;
  margin-bottom: 50px;
}

@media only screen and (max-width: 640px) {
  #media .row .box {
    width: 100% !important;
  }
}

#media .row .box h3 {
  text-align: left;
  margin-top: 15px;
}

#media .row .box p {
  text-align: left;
}

#media .row .box img {
  width: 380px !important;
  height: 380px !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}
